import request from '@/common/axios';
import store from '@/store';

// 押金设置列表
export function fetchDepositSettingList(data) {
  return request({
    url: '/web/admin/goods/adminDepositGoodsPage',
    method: 'POST',
    data:
      data,
  });
}

// 添加押金设置
export function addDepositSetting(data) {
  return request({
    url: '/web/admin/goods/addDepositGoods',
    method: 'POST',
    data: data,
  });
}

// 修改押金设置
export function updateDepositSetting(data) {
  return request({
    url: '/web/admin/goods/updateDepositGoods',
    method: 'POST',
    data: data,
  });
}

// 退还押金列表
export function fetchDepositList(data) {
  return request({
    url: '/web/admin/goods/adminDepositGoodsPage',
    method: 'POST',
    data:data
  });
}

// 退还押金审核
export function auditReturnDeposit(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2608,
      "oReqData": data
    }
  });
}

// 退还押金确认
export function confirmReturnDeposit(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2607,
      "oReqData": data
    }
  });
}

// 导出押金列表
export function exportDepositList(data) {
  return request({
    url: '/excel/userRefund/down',
    unsys: true,
    method: 'POST',
    responseType: 'arraybuffer',
    data: {
      "iReqCode": 0,
      "oReqData": data,
    }
  });
}