<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select placeholder="押金状态" v-model="query.status" clearable @change="getList(1)">
          <el-option v-for="(item, index) in this.const.RETURN_DEPOSIT_STATUS" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-col>

        <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>


      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportDepositList">导出押金退款列表</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="姓名" prop="realName"> </el-table-column>
      <el-table-column label="手机号" prop="userName"> </el-table-column>

      <el-table-column label="提现金额" prop="goodsPrice" align="right">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.goodsPrice / 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="提现方式" prop="payWay">
        <template #default="scope">
          <eb-badge
            :list="this.const.PAYMENT_TYPE"
            :target="scope.row.payWay"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.RETURN_DEPOSIT_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="申请时间" prop="createAt"></el-table-column>
      <el-table-column label="审核时间" prop="updateAt"> </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <span
            v-auth="this.per.DEPOSIT_AUDIT"
            v-if="scope.row.status === this.const.RETURN_DEPOSIT_STATUS_CODE.APPLY"
            class="option option-primary"
            @click="
              doAudit(scope.row, this.const.RETURN_DEPOSIT_STATUS_CODE.PASS)
            "
            >通过审核</span
          >

          <span
            v-auth="this.per.DEPOSIT_AUDIT"
            v-if="
              scope.row.status == this.const.RETURN_DEPOSIT_STATUS_CODE.APPLY
            "
            class="option option-danger"
            @click="
              doAudit(scope.row, this.const.RETURN_DEPOSIT_STATUS_CODE.REJECT)
            "
            >驳回审核</span
          >

          <span
            v-auth="this.per.DEPOSIT_AUDIT_CONFIRM"
            v-if="scope.row.status == this.const.RETURN_DEPOSIT_STATUS_CODE.PASS"
            class="option option-primary"
            @click="
              doConfirm(scope.row, this.const.RETURN_DEPOSIT_STATUS_CODE.RETURNED)
            "
            >确认退款</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchDepositList, auditReturnDeposit ,confirmReturnDeposit , exportDepositList} from "@/api/deposit";
import moment from 'moment';
export default {
  data() {
    let startTime = moment()
      .subtract(1, "m")
      .startOf("d")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      queryDate: {
        startTime: startTime,
        endTime: endTime,
      },

      dataSource: [],
      query: {
        reason: "",
        amount: -1,
        orderId: -1,
        payWay: -1,
        updateAt: "",
        bankName: "",
        type: -1,
        createAt: "",
        realName: "",
        bankNo: "",
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      total: 0,
    };
  },
  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.queryDate.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.queryDate.endTime = moment(e[1])
          .endOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    doExportDepositList() {
      exportDepositList({
        startTime: this.queryDate.startTime,
        endTime: this.queryDate.endTime,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.queryDate.startTime}-${this.queryDate.endTime}押金退款记录.xlsx`
        );
      });
    },
    
    //提现申请审核
    doAudit(data, type) {
      let pass = <span class="text-primary">通过</span>;
      let reject = <span class="text-danger">驳回</span>;
      let s = (
        <div>
          是否要{" "}
          {type === this.const.RETURN_DEPOSIT_STATUS_CODE.PASS
            ? pass
            : reject}{" "}
          提现审核?
        </div>
      );
      this.$confirm(s, "提示", {
        confirmButtonText: "确定",      
        cancelButtonText: "取消",
        type: "warning",                          
      }).then(() => {
        auditReturnDeposit({
          status: type,
          id: data.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });

          this.getList();
        });
      });
    },

    //提现申请审核
    doConfirm(data, type) {
      let pass = <span class="text-primary">通过</span>;
      let reject = <span class="text-danger">驳回</span>;
      let s = (
        <div>
          是否要{" "}
          {type === this.const.RETURN_DEPOSIT_STATUS_CODE.RETURNED
            ? pass
            : reject}{" "}
          确认提现?
        </div>
      );
      this.$confirm(s, "提示", {
        confirmButtonText: "确定",      
        cancelButtonText: "取消",
        type: "warning",                          
      }).then(() => {
        confirmReturnDeposit({
          status: type,
          id: data.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "提现成功!",
          });

          this.getList();
        });
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchDepositList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("fetchAgentListAll").then((res) => {
      this.getList();
    });
  },
};
</script>
